import React from 'react'
import { Link, useStaticQuery, graphql} from 'gatsby'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { Layout, Parallax, DelayedLink } from '@common'
import {
	page__wrapper,
	page__quote,
	page__parallax,
	page__parallax__inner,
	page__buttons,
	page__button,
	page__button__alt,
	page__testimonial,
	page__list,
	page__grid,
	page__grid__row,
} from './styles/page.module.scss'

const Includes = ({ pageContext, location }) => {
	const { WP } = useStaticQuery(graphql`
	query {
		WP {
			mediaItemBy(slug: "arnold-francisca-FBNxmwEVpAc-unsplash") {
				sourceUrl
				srcSet
				altText
			}
		}
	}
`)
	return(
	<Layout
		location={location}
		page={pageContext.page}

	>
		<div className={page__wrapper}>
			<h1>Jakie umiejki możesz przećwiczyć z moim zbiorem?</h1>
			<h2>
				Starałem się, aby dobrane przeze mnie zadania były
				<strong> komplementarne</strong> i pozwoliły Ci na
				przetrenowanie 130% wyzwań, z którymi będziesz się mierzyć w
				pracy programisty Frontend.
			</h2>

			<h2>
				...ale, niektóre zadania są dla najlepszych,{' '}
				<strong>stworzone dla sztuki</strong>, abyś sprawdził czy
				potrafisz coś zrobić
			</h2>

			<h1>Poniższa lista wyjaśni, co jest w każdym zbiorze:</h1>

			<h1 />

			<ul className={page__grid}>
				<li>
					<h3>Przećwicz tworzenie struktury stron</h3>
					<div className={page__grid__row}>
						<h4>HTML to szkielet</h4>
						<h6>
							Trzeba zacząć od podstaw. Ucząc się poprawnie
							stawiać szkielet ułatwisz sobie pracę, a
							przeglądarkom właściwe indeksowanie Twojej strony
						</h6>
					</div>
				</li>
				<li>
					<h3>Wymyśl schludny i elegancki wygląd</h3>
					<div className={page__grid__row}>
						<h4>CSS to makeup</h4>
						<h6>
							Dodając trochę kolorów oraz kształtów upiększysz
							swoje dzieło, a jako wisienka na torcie warto jest
							zająć się ładnymi i płynnymi animacjami
						</h6>
					</div>
				</li>
				<li>
					<h3>Zaprojektuj to tak, aby wszystko działało</h3>
					<div className={page__grid__row}>
						<h4>JAVASCRIPT to mięśnie</h4>
						<h6>
							Każda strona i aplikacja, nad którą będziesz
							pracować sprowadza się do dobrze naoliwionego
							Javascriptu, do którego w tych pakietach przykładam
							szczególną uwagę. Ponieważ strony muszą sprawnie
							działać
						</h6>
					</div>
				</li>
				<li>
					<h3>Ułatw i przyspiesz sobie pracę z kodem</h3>
					<div className={page__grid__row}>
						<h4>React to ODŻYWKI</h4>
						<h6>
							A oto i mój ulubieniec - React. Ułatwienie dla
							każdego Frontend Developera oraz Twoja główna
							wartość na rynku. Ambitnych wyzwań z Reacta tutaj
							nie zabrakło i stanowią dużą część zaawansowanego
							zestawu
						</h6>
					</div>
				</li>
				<li>
					<h3>Upewnij się, że dobrze Twój kod się nie wysypie</h3>
					<div className={page__grid__row}>
						<h4>TESTY to siłownia</h4>
						<h6>
							Ćwicząc pisanie testów pracujesz nad jakością
							swojego kodu i jako odpowiedzialny programista
							upewniasz się, że Twój kod nie zawiedzie
						</h6>
					</div>
				</li>
				<li>
					<h3>Nigdy nie przestawiaj ćwiczyć</h3>
					<div className={page__grid__row}>
						<h4>PROJEKTY to dalsza motywacja do działania</h4>
						<h6>
							Rozwój i zdobywanie nowej wiedz to podstawa pracy w
							IT. Chcę podrzucić Ci kilka pomysłów na ciekawe
							portfolio
						</h6>
					</div>
				</li>
			</ul>

			<div className={page__parallax}>
				<Parallax height={400} image={WP.mediaItemBy}>
					<div className={page__parallax__inner}>
						<h1>
							<strong>Moim celem</strong> jest stworzenie zbioru,
							którego przećwiczenie w całości zajmie Ci minimum 3
							miesiące ciężkiego, całodniowego kodowania
						</h1>
					</div>
				</Parallax>
			</div>

			<div className={page__buttons}>
				<div className={`${page__button}`}>
					<span>&gt;</span>
					<DelayedLink to={'/help/'}>Możesz mi pomóc</DelayedLink>
				</div>
			</div>
		</div>
	</Layout>
)}

export default Includes
